import React from 'react'
import PropTypes from 'prop-types'

import Arrow from '../images/arrow.inline.svg'

const Button = ({ children, isDark, isVertical, noArrow, width, ...props }) => {
  const lightStyles =
    'border-tuya-dark text-tuya-dark hover:text-white hover:border-tuya-slate-light focus:ring-tuya-dark'
  const darkStyles =
    'border-white text-white hover:text-tuya-dark hover:border-white focus:ring-white'
  const paddings = isVertical ? 'px-4 py-14' : 'py-4 px-14'
  const arrowPosition = isVertical
    ? 'left-1/2 bottom-3 -translate-x-1/2'
    : 'top-1/2 right-3 -translate-y-1/2'
  return (
    <button
      style={{ width }}
      className={`${
        isDark ? lightStyles : darkStyles
      } border-solid border ${paddings} uppercase text-button font-bold tracking-button relative group transition-all flex items-center justify-center focus:outline-none  focus:ring-2 focus:ring-opacity-50`}
      {...props}
    >
      <span className={`relative z-10 ${isVertical ? 'vertical-text' : ''}`}>{children}</span>
      {/* <Arrow className="absolute left-1/2 bottom-3 transform -translate-x-1/2" /> */}
      {!noArrow && <Arrow className={`absolute w-3 h-3 ${arrowPosition} transform z-10`} />}
      <span
        className={`${
          isDark ? 'bg-tuya-slate-light' : 'bg-white'
        } absolute h-full w-0 top-0 left-0 z-0 group-hover:w-full transition-all duration-300`}
      />
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isDark: PropTypes.bool,
  isVertical: PropTypes.bool,
}

Button.defaultProps = {
  isDark: false,
  isVertical: false,
}

export default Button
