import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { motion, AnimatePresence, useViewportScroll } from 'framer-motion'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { window } from 'browser-monads'

// Components
import Button from './button'

// Hooks
import useScrollDirection from '../hooks/useScrollDirection'
import useWindowSize from '../hooks/useWindowSize'

// Assets
import Logo from '../images/logo.inline.svg'
import Pattern from '../images/tuya-pattern-menu.inline.svg'
import CloseIcon from '../images/close-icon.inline.svg'

// Styles
import '../styles/components/header.scss'
import { useState } from 'react'

const menuItems = [
  'Home',
  'Food',
  'Bar',
  'Private Events',
  // `Chef's Table`,
  'Concept',
  'Jobs',
  'Contact',
]

const HamburgerButton = ({ onClick, hasLightBg }) => (
  <button
    onClick={onClick}
    className="hamburger-button focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-tuya-dark z-50 lg:z-0"
  >
    {[0, 1, 2].map(x => (
      <div key={x} className={`stripe ${hasLightBg ? 'bg-tuya-dark' : 'bg-white'}`} />
    ))}
  </button>
)

const Menu = ({ pathname, data, onClose, innerRef }) => (
  <motion.div
    ref={innerRef}
    initial={{ x: '-100%' }}
    animate={{
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 40,
      },
    }}
    exit={{
      x: '-100%',
      transition: {
        type: 'spring',
        stiffness: 500,
        damping: 40,
      },
    }}
    transition
    key="menu-bar"
    style={{ maxWidth: 560 }}
    className="fixed h-screen w-full lg:w-3/5 xl:w-2/5 bg-tuya-slate left-0 top-0 z-40 flex justify-between items-center lg:items-start flex-col pt-40 pb-20 lg:py-16 lg:px-32"
  >
    <button onClick={onClose} className="absolute top-10 right-9 hidden lg:block">
      <CloseIcon />
    </button>
    <ul className="text-3xl lg:text-menuItem leading-snug font-medium">
      {menuItems.map(item => {
        let slug = `/${item.toLowerCase().split(' ').join('-')}`
        if(slug === '/private-events') {
          slug = '/private-dining'
        }
        return (
          <li
            className={`${
              slug === pathname ||
              (slug === '/home' && pathname === '/') ||
              (slug === `/chef's-table` && pathname === '/chef-table')
                ? 'text-white'
                : ' text-tuya-menu'
            } hover:text-white transition-colors whitespace-nowrap`}
            key={item}
          >
            <Link to={slug.replace(`'s`, '').replace('home', '')}>{item}</Link>
          </li>
        )
      })}
    </ul>
    <Pattern className="hidden lg:block text-white opacity-50" />
    <div className="items-start lg:hidden">
      <div className="opening-hours mb-8">
        <h6 className="text-tuya-yellow mb-1 small-text-spaced">OPENING HOURS</h6>
        <p className="text-white">
          {data.normalStartDay}-{data.normalEndDay}: {data.normalInterval}
        </p>
        {'' !== data.weekendStartDay && '' !== data.weekendEndDay && (
          <>
            <p className="text-white">
              {data.weekendStartDay === data.weekendEndDay ? (
                <>
                  {data.weekendStartDay}: {data.weekendInterval}
                </>
              ) : (
                <>
                  {data.weekendStartDay}-{data.weekendEndDay}: {data.weekendInterval}
                </>
              )}
            </p>
          </>
        )}
      </div>
      <Link to="/reservation">
        <Button>RESERVATION</Button>
      </Link>
    </div>
  </motion.div>
)
const Header = ({ siteTitle, pathname, hasLightBg, simpleHeader, alwaysBlack }) => {
  const [isOpened, setIsOpened] = useState(false)
  const [isPassedNav, setIsPassedNav] = useState(false)
  const [isHalfPassedNav, setIsHalfPassedNav] = useState(false)
  const { scrollY } = useViewportScroll()
  const scrollDirection = useScrollDirection()
  const windowSize = useWindowSize()

  useEffect(
    () =>
      scrollY.onChange(v => {
        setIsPassedNav(v > 100)
        setIsHalfPassedNav(v > 37)
      }),
    [scrollY],
  )
  useEffect(() => {
    if (isOpened) {
      if (windowSize.width > 768) window.scrollTo(0, 0)
      disableBodyScroll(menuEL.current)
    } else clearAllBodyScrollLocks()
  }, [isOpened, windowSize])
  const menuEL = useRef(null)
  const graphqlData = useStaticQuery(graphql`
    query InformationQuery {
      datoCmsResturantInformation {
        normalStartDay
        normalEndDay
        normalInterval
        weekendStartDay
        weekendEndDay
        weekendInterval
      }
    }
  `)
  const data = graphqlData.datoCmsResturantInformation
  return (
    <>
      <motion.header
        initial={false}
        animate={
          scrollDirection === 'down' && windowSize.width < 768 && isHalfPassedNav
            ? {
                y: '-100%',
                transition: {
                  type: 'spring',
                  duration: 0.2,
                },
              }
            : {
                y: 0,
                transition: {
                  type: 'spring',
                  stiffness: 400,
                  damping: 40,
                },
              }
        }
        transition={{}}
        className={`${simpleHeader ? '' : 'md:bg-black'} md:bg-opacity-60 w-full ${
          isPassedNav ? 'bg-black pt-8' : ''
        }
        ${alwaysBlack ? 'md:bg-opacity-100 bg-black' : ''}
        fixed md:absolute z-50 navbar flex items-center px-6 justify-between transition-colors`}
      >
        <HamburgerButton
          onClick={() => setIsOpened(old => !old)}
          hasLightBg={
            (hasLightBg && !isPassedNav && windowSize.width < 768) ||
            (simpleHeader && !alwaysBlack && windowSize.width > 767)
          }
        />
        {!simpleHeader && (
          <div className="md:flex items-start hidden">
            <Link to="/reservation">
              <Button>RESERVATION</Button>
            </Link>
            <div className="opening-hours ml-8 place-self-center">
              <h6 className="text-tuya-yellow mb-1 small-text-spaced">OPENING HOURS</h6>
              <p className="text-white">
                {data.normalStartDay}-{data.normalEndDay}: {data.normalInterval}
              </p>
              {'' !== data.weekendStartDay && '' !== data.weekendEndDay && (
                <p className="text-white">
                  {data.weekendStartDay === data.weekendEndDay ? (
                    <>
                      {data.weekendStartDay}: {data.weekendInterval}
                    </>
                  ) : (
                    <>
                      {data.weekendStartDay}-{data.weekendEndDay}: {data.weekendInterval}
                    </>
                  )}
                </p>
              )}
            </div>
          </div>
        )}
        <Link className="text-white z-50" to="/">
          <Logo
            className={`${
              (hasLightBg && !isPassedNav && windowSize.width < 768) ||
              (simpleHeader && !alwaysBlack && windowSize.width > 767)
                ? 'text-tuya-slate'
                : ''
            }`}
          />
        </Link>
        <AnimatePresence>
          {isOpened && (
            <Menu
              pathname={pathname}
              data={data}
              onClose={() => setIsOpened(false)}
              innerRef={menuEL}
            />
          )}
        </AnimatePresence>
      </motion.header>
      <AnimatePresence>
        {isOpened && (
          <motion.div
            onClick={() => setIsOpened(false)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            key="overlay"
            className="bg-black h-screen w-full fixed top-0 left-0 z-40"
          />
        )}
      </AnimatePresence>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
