/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

import WhatsappIcon from '../images/whatsapp.inline.svg'

const Layout = ({ children, className, pathname, hasLightBg, simpleHeader, alwaysBlack }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        pathname={pathname}
        hasLightBg={hasLightBg}
        simpleHeader={simpleHeader}
        alwaysBlack={alwaysBlack}
      />
      <div className={`${className}`}>
        <main>{children}</main>

        <Footer />
      </div>
      <div className="whatsapp-wrapper">
        <a href="https://wa.me/40722224420" target="_blank" color="#4dc247">
          <WhatsappIcon />
        </a>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
