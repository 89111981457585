import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

// Components

// Assets
import Logo from '../images/logo.inline.svg'
import FacebookIcon from '../images/facebook.inline.svg'
import InstagramIcon from '../images/instagram.inline.svg'
import PinterestIcon from '../images/pinterest.inline.svg'
import Pattern from '../images/tuya-footer-pattern.inline.svg'

const Footer = () => {
  const graphqlData = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsResturantInformation {
        address
        phone
        email
        pinterestLink
        instagramLink
        facebookLink
      }
      datoCmsFood {
        menuFile {
          url
        }
      }
    }
  `)
  let {
    address,
    email,
    phone,
    pinterestLink,
    instagramLink,
    facebookLink,
  } = graphqlData.datoCmsResturantInformation
  const foodAllergensLink = graphqlData.datoCmsFood.menuFile.url
  address = address.split(', ')
  const addressPart1 = address.shift()
  const addressPart2 = address.join(', ')
  // const addressPart1 = address

  function cleanPhone(phone) {
    return phone.replace(/[\W_]+/g, '')
  }

  return (
    <footer className="overflow-hidden mt-28 px-10 pt-20 xl:px-24 lg:pt-28 pb-5 md:pb-20 lg:pb-5 bg-tuya-dark-less text-tuya-footer-grey">
      <div className="grid grid-cols-1 md:grid-cols-3 md:auto-rows-max lg:auto-rows-auto lg:gridrow lg:grid-cols-4 gap-y-14 lg:gap-y-2 mb-16 md:mb-0 max-w-screen-xl mx-auto">
        <div className="text-tuya-blue col-span-3 lg:col-auto">
          <Link to="/">
            <Logo className="h-8" />
          </Link>
        </div>
        <h6 className="text-tuya-light-blue opacity-30 tracking-three text-xs hidden lg:block ">
          THE PLACE
        </h6>
        <h6 className="text-tuya-light-blue opacity-30 tracking-three text-xs hidden lg:block">
          LINKS
        </h6>
        <h6 className="text-tuya-light-blue opacity-30 tracking-three text-xs hidden lg:block">
          CONTACT
        </h6>
        <div className="row-start-5 lg:row-span-2 md:row-auto md:row-start-3 md:col-span-2 lg:col-start-5 lg:row-start-1 lg:row-end-3 ">
          {/*{isSubmitted ? (*/}
          {/*  <p className="text-tuya-blue font-light tracking-wide mb-3 text-center">*/}
          {/*    Thank you for subscribing to our newsletter!*/}
          {/*  </p>*/}
          {/*) : (*/}
          {/*  <>*/}
          {/*    <p className="text-tuya-blue font-light tracking-wide mb-3">*/}
          {/*      Subscribe to our newsletter!*/}
          {/*    </p>*/}
          {/*    {isLoading ? (*/}
          {/*      <div className="text-center">*/}
          {/*        <BeatLoader color="white" loading={true} size={8} />*/}
          {/*      </div>*/}
          {/*    ) : (*/}
          {/*      <form*/}
          {/*        onSubmit={handleSubmit(onSubmit)}*/}
          {/*        name="Newsletter"*/}
          {/*        method="post"*/}
          {/*        data-netlify="true"*/}
          {/*        data-netlify-honeypot="bot-field"*/}
          {/*      >*/}
          {/*        <input type="hidden" name="form-name" value="Newsletter" />*/}
          {/*        <div className="mb-3">*/}
          {/*          <input*/}
          {/*            style={{ maxWidth: 272 }}*/}
          {/*            className={`w-full bg-transparent border ${*/}
          {/*              errors.email ? 'border-red-500' : 'border-tuya-slate-dark'*/}
          {/*            } px-5 py-3 text-xs placeholder-tuya-light-blue placeholder-opacity-30 focus:outline-none focus:ring-2 focus:ring-tuya-light-blue focus:ring-opacity-10`}*/}
          {/*            type="text"*/}
          {/*            placeholder="Enter your email address"*/}
          {/*            name="email"*/}
          {/*            ref={register({*/}
          {/*              required: 'This field is required',*/}
          {/*              pattern: {*/}
          {/*                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,*/}
          {/*                message: 'Please enter valid email address',*/}
          {/*              },*/}
          {/*            })}*/}
          {/*          />*/}
          {/*          {errors && errors.email && (*/}
          {/*            <p className="text-xxs text-red-500">{errors.email.message}</p>*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*        <div className="flex space-x-2 items-center text-tuya-light-blue opacity-70 mb-5">*/}
          {/*          <Checkbox*/}
          {/*            innerRef={register({*/}
          {/*              validate: value => value === true,*/}
          {/*            })}*/}
          {/*            fontSize={10}*/}
          {/*            color={errors && errors.checkbox && 'text-red-500'}*/}
          {/*          >*/}
          {/*            Sign up to newsletter and agree to Privacy Policy*/}
          {/*          </Checkbox>*/}
          {/*        </div>*/}
          {/*        <button className="flex items-center text-tuya-blue font-light ">*/}
          {/*          Subscribe <Arrow className="w-3 h-3 ml-3" />*/}
          {/*        </button>*/}
          {/*      </form>*/}
          {/*    )}*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
        <div className="lg:flex flex-col justify-center row-start-2 ">
          <h6 className="text-tuya-light-blue opacity-30 tracking-three text-xs mb-4">LOCATIONS</h6>
          <ul className="text-sm leading-6">
            <li>Bucharest</li>

            <li>
              <a href="https://www.tuya.gr/" target="_blank">
                Mykonos
              </a>
            </li>
          </ul>
        </div>
        <div className="row-start-3 md:row-start-2 lg:row-auto">
          <h6 className="text-tuya-light-blue opacity-30 tracking-three mb-4 text-xs lg:hidden">
            THE PLACE
          </h6>
          <ul className="text-sm leading-8">
            <li>
              <Link to="/food">Food Menu</Link>
            </li>
            <li>
              <Link to="/concept">Bar menu</Link>
            </li>
            <li>
              <Link to="/private-dining">Private Events</Link>
            </li>
            {/* <li>
              <Link to="/chef-table">Chef Table</Link>
            </li> */}
          </ul>
        </div>

        <div className="row-start-3 md:row-start-2 lg:row-auto">
          <h6 className="text-tuya-light-blue opacity-30 tracking-three mb-4 text-xs lg:hidden">
            LINKS
          </h6>
          <ul className="text-sm leading-8">
            <li>
              <Link to="/jobs">Jobs</Link>
            </li>
            <li>
              <Link to="/privacy-and-cookie-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/privacy-and-cookie-policy">Cookie Policy</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </li>
            <li>
              <a href={foodAllergensLink} target="_blank">
                Check out our nutritional facts
              </a>
            </li>
          </ul>
        </div>
        <div className="row-start-4 md:row-start-2 lg:row-auto">
          <h6 className="text-tuya-light-blue opacity-30 tracking-three mb-4 text-xs lg:hidden">
            CONTACT
          </h6>
          <ul className="text-sm leading-8">
            <li>{addressPart1},</li>
            <li>{addressPart2}</li>
            <li>
              <a href={`tel:+${cleanPhone(phone)}`}>{phone}</a>
            </li>
            <li>{email}</li>
          </ul>
        </div>
        <div className="row-start-6 md:row-start-3 md:col-start-2 md:text-center lg:row-start-3 lg:col-start-1 lg:col-end-6 lg:pt-16 relative">
          <h6 className="text-tuya-light-blue opacity-30 tracking-three text-xs mb-7">
            WE ARE SOCIAL
          </h6>
          <div className="flex md:justify-center text-tuya-blue mb-14 ">
            <Pattern className="hidden 2xl:block absolute -left-36 " />
            <a href={facebookLink}>
              <FacebookIcon className="h-9 mr-10" />
            </a>
            <a href={instagramLink}>
              <InstagramIcon className="h-9  mr-10" />
            </a>
            <a href={pinterestLink}>
              <PinterestIcon className="h-9" />
            </a>
            <Pattern className="hidden 2xl:block absolute -right-36 " />
          </div>
          <div className="w-full overflow-hidden 2xl:hidden">
            <Pattern className="lg:mx-auto lg:mb-10 mb-5 md:hidden lg:block" />
          </div>
          <p
            style={{ fontSize: 11, letterSpacing: '1.1px' }}
            className="text-tuya-light-blue opacity-60"
          >
            © {new Date().getFullYear()} Tuya | All rights reserved
          </p>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
